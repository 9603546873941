<template>
  <vb-single-column :campaignInfo="campaignInfo">
    <v-container>
      <v-row dense no-gutters class="flex-nowrap justify-center mt-5 mb-9 mx-auto pt-4" style="max-width: 728px">
        <v-col cols="4" md="auto" class="px-3">
          <a v-if="isBeforeJulyEnd" class="banners-in-a-row line-campaign-banner" href="https://liff.line.me/1655256031-gljX9M9A/8cae760d00f24a33888923c16a861618"
             @click="trackEvent('clicked_line')">
            <v-img class="mx-auto" :src="`${cdnUrl}/assets/images/index/line-1000points-${smAndDown ? 'sp' : 'pc'}.png`" alt="LINE連携でお買い物に使える1000ポイントをプレゼント" width="100%" max-width="218" :key="smAndDown ? 'sp' : 'pc'" />
          </a>
          <a v-else class="banners-in-a-row repeater-banner" href="https://liff.line.me/1655256031-gljX9M9A/8cae760d00f24a33888923c16a861618"
             @click="trackEvent('clicked_line')">
            <v-img class="mx-auto" :src="`${cdnUrl}/assets/images/index/line_banner_2024_${smAndDown ? 'sp' : 'pc'}.png`" alt="LINE友達登録で買取送料無料クーポンプレゼント" width="100%" max-width="218" :key="smAndDown ? 'sp' : 'pc'" />
          </a>
        </v-col>
        <v-col cols="4" md="auto" class="px-3">
          <a href="/endpaper/8031/">
            <v-img class="mx-auto" :src="`${cdnUrl}/assets/images/index/beginner_banner_${smAndDown ? 'sp' : 'pc'}.jpg`" alt="はじめての方へ" width="100%" max-width="218" :key="smAndDown ? 'sp' : 'pc'" />
          </a>
        </v-col>
        <v-col cols="4" md="auto" class="px-3">
          <a v-if="isOnChristmasCampaign" class="banners-in-a-row christmas-campaign-banner" href="/search?conditions_stock=2">
            <v-img class="mx-auto" :src="`${cdnUrl}/assets/images/campaign/campaign-sell-2412-${smAndDown ? 'sp' : 'pc'}.png`" alt="クリスマスキャンペーン　購入金額の10%ポイント還元" width="100%" max-width="218" :key="smAndDown ? 'sp' : 'pc'" />
          </a>
          <a v-else-if="isDisplayLowPriceCampaignBanner" class="banners-in-a-row low-price-campaign-banner" href="/search?conditions_stock=2&conditions_sorting=2&conditions_price%5B%5D=&conditions_price%5B%5D=100">
            <v-img class="mx-auto" :src="`${cdnUrl}/assets/images/index/price_100_sale_${smAndDown ? 'sp' : 'pc'}.png`" alt="ワンコイン100円で買える本特集！古本セール開催中！" width="100%" max-width="218" :key="smAndDown ? 'sp' : 'pc'" />
          </a>
          <a v-else-if="isDisplayTransferCampaignBanner" class="banners-in-a-row buy-banner-campaign" href="/sell">
            <v-img class="mx-auto" :src="`${cdnUrl}/assets/images/index/transferCampaignBanner_202212_${smAndDown ? 'sp' : 'pc'}_240x120.png`" alt="ソクフリキャンペーン買取金額500円アップ中！" width="100%" max-width="218" :key="smAndDown ? 'sp' : 'pc'" />
          </a>
          <a v-else class="banners-in-a-row buy-banner" href="/sell">
            <v-img class="mx-auto" :src="`${cdnUrl}/assets/images/index/buy_banner_${smAndDown ? 'sp' : 'pc'}.jpg`" alt="買取の申込みはこちら" width="100%" max-width="218" :key="smAndDown ? 'sp' : 'pc'" />
          </a>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-card id="resale_campaign_area"
            class="d-flex flex-column align-center justify-space-around mb-2"
            :class="smAndDown ? (isResaleCampaignDisplay||is16thCampaignDisplay) ? 'px-3' : 'px-13': null"
            flat :width="isResaleCampaignDisplay ? is16thCampaignDisplay ? 'inherit' : 'fit-content' : width">
            <div v-for="(campaign, index) in this.getResaleCampaign" :key="index" style="display: contents;" :id="`resale_campaign_banner_${index}`">
              <timer-display :start="campaign.startDate" :end="campaign.endDate" @isDisplay="($event ? isResaleCampaignDisplay = $event : '')">
                <v-sheet class="mt-n2 mt-md-n4 mb-4" width="100%" max-width="409">
                  <a :href="!!campaign.link ? campaign.link : ''" :style="!!campaign.link ? 'cursor:pointer;' : 'pointer-events:none;'">
                    <img class="rounded-lg" :style="smAndDown ? null : 'height:109px'" :src="`${cdnUrl}${campaign.image}`" :alt="campaign.message"/>
                  </a>
                </v-sheet>
              </timer-display>
            </div>

            <timer-display start='2023-06-01 00:00:00' end='2023-07-05 23:59:59' @isDisplay="($event ? is16thCampaignDisplay = $event : '')" style="width:100%;">
              <campaign-advertise-on-top />
            </timer-display>

          <v-sheet flat v-if="!isResaleCampaignDisplay && !is16thCampaignDisplay" id="top_pickup_books_banner">
            <img :src="`${cdnUrl}/assets/images/index/catch-copy.svg`" alt="話題の新刊からお買い得本までここで買えるおすすめ本" width="334" height="63"/>
          </v-sheet>
        </v-card>
      </v-row>
      <v-sheet id="scroll_down_guide_wrapper">
        <v-row justify="center" class="mb-1 mt-3"
          :class="smAndDown ? 'text-caption': 'text-minimum'"
          v-if="!is16thCampaignDisplay"
        >
          <span>
            <v-img contain :src="`${cdnUrl}/assets/images/index/scrolldown.svg`" alt="SCROLL DOWN"/>
          </span>
        </v-row>
        <v-row justify="center" class="mb-8" v-if="!is16thCampaignDisplay">
          <svg width="1" height="40" xmlns="http://www.w3.org/2000/svg" style="background-color: #EAEAEA">
            <defs>
              <clipPath id="clip-path">
                <rect x="0" y="0" width="1" height="40" />
              </clipPath>
            </defs>
            <line x1="0.5" y1="0" x2="0.5" y2="60" style="stroke: black; stroke-width: 1px;" clip-path="url(#clip-path)">
              <animate attributeName="y1" from="0" to="40" dur="1s" repeatCount="indefinite" />
              <animate attributeName="y2" from="30" to="60" dur="1s" repeatCount="indefinite" />
            </line>
          </svg>
        </v-row>
      </v-sheet>
    </v-container>

    <v-sheet flat :class="smAndDown ? 'pl-4 pt-7': 'pl-16 pt-10'">
      <slide-card
        title="書店オフィシャルライブラリ"
        subtitle="個性豊かな書店がつくる特別なライブラリをチェック！"
        :items="bookstoreLibraryList"
        hide-icon
        card-bg-color="#F5F5F5"
      ></slide-card>
    </v-sheet>

    <v-sheet :style="smAndDown ? 'min-height:404px;': 'min-height:464px;'">
      <v-card
        v-if="vbOfficialItems.folderItems.length"
        flat
        tile
        :class="smAndDown ? 'pl-4 pt-7': 'pl-16 pt-10'">
        <slide-item
            :title="vbOfficialItems.folderDetails.title ? vbOfficialItems.folderDetails.title : ''"
            :subtitle="vbOfficialItems.folderDetails.description"
            :link-url="vbOfficialItems.folderDetails.folderId ? '/shelf-items/folder/' + vbOfficialItems.folderDetails.folderId : null"
            :items="vbOfficialItems.folderItems"/>
      </v-card>
    </v-sheet>

    <v-card
        flat
        tile
        color="#EAEAEA"
        class="pt-8 pb-8"
        :class="smAndDown ? 'pl-4': 'pl-16'">
      <v-img
          contain
          width="234"
          class="speech-bubble-title"
          :src="`${cdnUrl}/assets/images/index/speech-bubble-featured.svg`"
          alt="いま話題の新刊と出会う"/>
      <slide-item v-if="new_list1.length" title="オールジャンル" link-url="search?conditions_stock=2" :items="new_list1"></slide-item>
      <slide-item v-if="new_list4.length" title="コミックセット" link-url="search?conditions_sorting=2&conditions_stock=2&conditions_category[]=19"
                  :items="new_list4"></slide-item>
      <slide-item v-if="new_list2.length" title="ビジネス" link-url="search?conditions_stock=2&conditions_category[]=8"
                  :items="new_list2"></slide-item>
      <slide-item v-if="new_list3.length" title="文芸" link-url="search?conditions_stock=2&conditions_category[]=5"
                  :items="new_list3"></slide-item>
      <search-by-genre
          :items="keywordList"
          :conditions="conditionsNew"
      />
    </v-card>
    <v-card
        flat
        tile
        class="mb-4"
        :class="smAndDown ? 'pl-4 pt-7': 'pl-16 pt-10'">
      <slide-item title="閲覧履歴" :items="history_list" :alt-item-text="history_alt_text"></slide-item>
      <slide-item title="閲覧履歴によるおすすめ" :items="recommend_by_browsing_history_list"
                  :alt-item-text="history_alt_text"></slide-item>

    </v-card>
    <v-card
        flat
        tile
        color="#EAEAEA"
        class="pt-8 pb-8"
        :class="smAndDown ? 'pl-4': 'pl-16'">
      <v-img
          contain
          width="234"
          class="speech-bubble-title"
          :src="`${cdnUrl}/assets/images/index/speech-bubble-under100.svg`"
          alt="100円以下で買えるお買い得本"/>
      <slide-item v-if="price_list1.length" title="オールジャンル" link-url="search?conditions_stock=2&conditions_price[]=&conditions_price[]=100"
                  :items="price_list1"></slide-item>
      <slide-item v-if="price_list2.length" title="ビジネス"
                  link-url="search?conditions_stock=2&conditions_category[]=8&conditions_price[]=&conditions_price[]=100"
                  :items="price_list2"></slide-item>
      <slide-item v-if="price_list3.length" title="文芸"
                  link-url="search?conditions_stock=2&conditions_category[]=5&conditions_price[]=&conditions_price[]=100"
                  :items="price_list3"></slide-item>
      <search-by-genre
          :items="keywordList"
          :conditions="conditionsPrice"
      />
    </v-card>
    <v-card
        flat
        tile
        class="mb-4"
        :class="smAndDown ? 'pl-4 pt-7': 'pl-16 pt-10'">
      <slide-item title="VALUE BOOKS オリジナルアイテム"
                  subtitle="トートバッグや特典付きの書籍、実店舗「NABO」で取り扱っている商品などをセレクト"
                  :items="valuebooksList"></slide-item>
    </v-card>
    <v-card
        flat
        tile
        color="#EAEAEA"
        class="pt-8 pb-8"
        :class="smAndDown ? 'pl-4': 'pl-16'">
      <v-img
          contain
          width="196"
          class="speech-bubble-title"
          :src="`${cdnUrl}/assets/images/index/speech-bubble-bestseller.svg`"
          alt="定番のベストセラー"/>
      <slide-item v-if="standard_list1.length" title="オールジャンル"
                  link-url="search?conditions_stock=2&conditions_category[]=1&conditions_category[]=2&conditions_category[]=3&conditions_category[]=4&conditions_category[]=5&conditions_category[]=6&conditions_category[]=7&conditions_category[]=8&conditions_category[]=9&conditions_category[]=10&conditions_category[]=12&conditions_category[]=15&conditions_category[]=17&conditions_category[]=18&conditions_category[]=19&conditions_age[]=2010-01-01&conditions_age[]="
                  :items="standard_list1"></slide-item>
      <slide-item v-if="standard_list2.length" title="人文・社会学"
                  link-url="search?conditions_stock=2&conditions_category[]=12&conditions_age[]=2010-01-01&conditions_age[]="
                  :items="standard_list2"></slide-item>
      <slide-item v-if="standard_list3.length" title="絵本"
                  link-url="search?conditions_stock=2&conditions_category[]=7&conditions_age[]=2010-01-01&conditions_age[]="
                  :items="standard_list3"></slide-item>
      <search-by-genre
          :items="keywordList"
          :conditions="conditionsPeriod"
      />
    </v-card>
    <v-card flat :class="smAndDown ? 'pl-4 pt-7': 'pl-16 pt-10'">
      <slide-card
        title="読みもの"
        link-url='/endpaper/'
        :items="articleList"
        card-width="200"
        card-height="212"
        :line-clamp="3"
      ></slide-card>
    </v-card>
    <audio-controller />
  </vb-single-column>
</template>

<script>
import {mapActions} from 'vuex';
import ItemListConfig from '../../config/itemlist.js';
import TimerDisplay from "../atoms/timer-display.vue";
import VbItem from "../molecules/vb-item.vue";
import AudioController from "../molecules/item/audio-controller.vue";
import SlideItem from "../organisms/top/slide-item.vue";
import SlideCard from "../organisms/top/slide-card.vue";
import SearchByGenre from "../organisms/top/search-by-genre.vue";
import CampaignAdvertiseOnTop from "../organisms/campaign-advertise-on-top.vue";
import VbSingleColumn from "../template/vb-single-column.vue";
import CampaignConfig from "../../config/campaign.js";

export default {
  name: "vb-top",
  async created() {

    // 閲覧履歴
    this.retrieveBibliographyBrowsingHistory();
    // 閲覧履歴によるレコメンド
    this.retrieveRecommendByBibliographyBrowsingHistory();


    const element = document.getElementById("bd_code1");
    this.customerId = element ? element.value : null;
    // 公式ライブラリアイテム
    const officialLibraryFolderIDKey = Object.keys(ItemListConfig.officialLibraryFolderIds[0])[0];
    this.retrieveOfficialLibraryItem(officialLibraryFolderIDKey, 'vbOfficialItems');
    //話題の新刊
    let params = {conditions_sorting: "1", conditions_stock: "2"}
    this.searchItem(params, "new_list1");
    params = {conditions_sorting: "1", conditions_stock: "2", conditions_category: ["8"]}
    this.searchItem(params, "new_list2");
    params = {conditions_sorting: "1", conditions_stock: "2", conditions_category: ["5"]}
    this.searchItem(params, "new_list3");
    params = {conditions_sorting: "2", conditions_stock: "2", conditions_category: ["19"]}
    this.searchItem(params, "new_list4");

    //五百円以下
    params = {conditions_sorting: "1", conditions_stock: "2", conditions_price: ["0", "100"]}
    this.searchItem(params, "price_list1");
    params = {
      conditions_sorting: "1",
      conditions_stock: "2",
      conditions_category: ["8"],
      conditions_price: ["0", "100"]
    }
    this.searchItem(params, "price_list2");
    params = {
      conditions_sorting: "1",
      conditions_stock: "2",
      conditions_category: ["5"],
      conditions_price: ["0", "100"]
    }
    this.searchItem(params, "price_list3");

    //定番のベストセラー
    params = {
      conditions_sorting: "1",
      conditions_stock: "2",
      conditions_category: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "12", "15", "17", "18", "19"],
      conditions_age: ["", "2010-01-01"]
    }
    this.searchItem(params, "standard_list1");
    params = {
      conditions_sorting: "1",
      conditions_stock: "2",
      conditions_category: ["12"],
      conditions_age: ["", "2010-01-01"]
    }
    this.searchItem(params, "standard_list2");
    params = {
      conditions_sorting: "1",
      conditions_stock: "2",
      conditions_category: ["7"],
      conditions_age: ["", "2010-01-01"]
    }
    this.searchItem(params, "standard_list3");

    // VALUE BOOKS オリジナルアイテム
    this.createValuebooksListItems();

    this.checkImmediateTransferCampaign();
    this.checkLowPriceCampaign();
    this.checkChristmasCampaign();
  },
  components: {
    VbSingleColumn,
    AudioController,
    TimerDisplay,
    SearchByGenre,
    VbItem,
    SlideItem,
    SlideCard,
    CampaignAdvertiseOnTop,
  },
  props:['vsCatalogId', 'campaignInfo'],
  data() {
    return {
      cdnUrl: VueConfig.cdn_url,
      readMoreExpanded: false,
      model: null,
      backToTopOfSlide: false,
      customerId: null,
      keywordList: ItemListConfig.keywordList,
      conditionsNew: [
        {
          name: 'conditions_stock',
          value: 2
        }
      ],
      conditionsPrice: [
        {
          name: 'conditions_stock',
          value: 2
        },
        {
          name: 'conditions_price[]',
          value: ''
        },
        {
          name: 'conditions_price[]',
          value: 100
        }
      ],
      conditionsPeriod: [
        {
          name: 'conditions_stock',
          value: 2
        },
        {
          name: 'conditions_age[]',
          value: '2010-01-01'
        },
        {
          name: 'conditions_age[]',
          value: ''
        }
      ],
      custmerId: null,
      new_list1: [],
      new_list2: [],
      new_list3: [],
      new_list4: [],
      price_list1: [],
      price_list2: [],
      price_list3: [],
      standard_list1: [],
      standard_list2: [],
      standard_list3: [],
      history_list: [],
      history_vs_catalog_id_list:[],
      recommend_by_browsing_history_list: [],
      historyParams: {
        serv: 'valuebooksweb',
        area: '',
        ratio: '',
        item: '',
        cat: '',
        excl: '',
        kw: '',
        filt: '',
        lang: ''
      },
      history_alt_text: null,
      recommend_by_history_alt_text: null,
      isResaleCampaignDisplay: false,
      is16thCampaignDisplay: false,
      articleList:ItemListConfig.articleList,
      valuebooksList: null,
      vbOfficialItems: {
        folderDetails: {},
        folderItems: []
      },
      isDisplayTransferCampaignBanner: false,
      isDisplayLowPriceCampaignBanner: false,
      isOnChristmasCampaign: false,
      isBeforeJulyEnd: new Date() <= new Date('2024-07-31T23:59:59+09:00'),
      bookstoreLibraryList: ItemListConfig.bookstoreLibraryList,
    }
  },
  methods: {
    ...mapActions('favorite', ['pushToFavoriteStore']),

    searchItem: async function (params, result) {
      const api = "/api/search";
      params.display_size = 20;
      params.request_from = "top";

      return axios
          .get(api, {
            params: params,
          })
          .then(({data}) => {
            if (data) {
              let part = data.items.slice(0, 20);
              this.updateFavoriteFlag(part);
              this[result].push(...part);
            }
          })
          .catch((error) => {
            console.debug(error);
          })
    },
    retrieveOfficialLibraryItem: async function (folderIDKey, resultDestination) {
      const api = "/api/library/OfficialFolder/items";
      let resultContainer = resultDestination;

      axios.get(api, {
        params: {
          folderIDKey: folderIDKey,
          numberOfItems: 20,
        }
      })
        .then(({data}) => {
          if (data) {
            if(!data.items.length) {
              return;
            }
            let displayItems = this.removedDuplicatesOfficialLibraryItem(data.items);
            this.updateFavoriteFlag(displayItems);
            this[resultContainer].folderItems.push(...displayItems);
            this[resultContainer].folderDetails = data.details;
          }
        })
        .catch((error) => {
          console.debug(error);
        })
    },
    retrieveBibliographyBrowsingHistory: async function(){
      const api = '/api/search/bibliographyBrowseHis';
      const _this = this;
      return axios.get(api)
          .then(function (response) {
            let partDisplayItems = response.data.items.slice(0,VueConfig.maxBrowsingHistoryDisplayCount);
            let originVsCatalogIdList = response.data.originVsCatalogIdList;
            _this.updateFavoriteFlag(partDisplayItems);
            _this["history_list"].push(...partDisplayItems);
            _this["history_vs_catalog_id_list"].push(...originVsCatalogIdList);
            _this.history_alt_text = _this.history_list.length ? null : '閲覧履歴がまだありません。<br>気になる本をチェックしてみてくださいね！';
            return true;
          }).catch(error => {
            return false;
          });
    },
    retrieveRecommendByBibliographyBrowsingHistory: async function () {
      const api = '/api/search/recommendByBibliographyBrowseHis';
      const _this = this;
      return axios.get(api,{
        "params": {
          max_display_size:50
        }
      })
          .then(function (response) {
            let partDisplayItems = response.data.items;
            _this.updateFavoriteFlag(partDisplayItems);
            _this["recommend_by_browsing_history_list"].push(...partDisplayItems);
            _this.recommend_by_history_alt_text = _this.recommend_by_browsing_history_list.length ? null : '閲覧履歴がない為おすすめ商品はありません。';
            return true;
          }).catch(error => {
            return false;
          });
    },
    removedDuplicatesOfficialLibraryItem(officialLibraryItems) {
      const resultOfRemoved = officialLibraryItems.filter(function (x, i, items) {
        // vs_catalog_id がnullの場合、またはコミックセットの場合はチェックしない（子コミックセットを考慮）
        if (!x.vs_catalog_id || x.vs_catalog_id.startsWith("VC")) {
          return x
        }
        return items.findIndex(function (y) {
          return y.vs_catalog_id === x.vs_catalog_id;
        }) === i
      });

      return resultOfRemoved;
    },
    // お気に入り登録されているかどうかのフラグをセット
    updateFavoriteFlag(items) {
      const vsCatalogIDs = items.map(item => item.vs_catalog_id);
      if(!vsCatalogIDs.length) {
        return;
      }
      axios.post('/api/library/folder/favorite/items/intersection', {
        vs_catalog_id: vsCatalogIDs,

      }).then(({data: favoriteVsCatalogIDs}) => {
        if (0 < favoriteVsCatalogIDs.length) {
          const setFavoriteVsCatalogIDs = new Set(favoriteVsCatalogIDs);
          items.forEach(item => {
            this.$set(item, 'isFavorite', setFavoriteVsCatalogIDs.has(item.vs_catalog_id));
          });
        }

      }).catch(e => {
        if (e.response.status !== 403) {
          console.error(e);
        }
      });
    },
    createValuebooksListItems() {
      const vbItemList = ItemListConfig.valuebooksList;
      this.updateFavoriteFlag(vbItemList);
      this.valuebooksList = vbItemList;
    },
    checkImmediateTransferCampaign() {
      const now = new Date();
      const immediateTransferCampaignItems = CampaignConfig.immediateTransferCampaign ?? [];
      for (const item of immediateTransferCampaignItems) {
        const startDate = new Date(item.start_date);
        const endDate = new Date(item.end_date);
        if (now >= startDate && now <= endDate) {
          if (item.display_amount && item.display_amount === '500') {
            this.isDisplayTransferCampaignBanner = true;
          }
        }
      }
    },
    checkLowPriceCampaign() {
      const now = new Date();
      const lowPriceCampaignConfig = CampaignConfig.low_price_campaign ?? [];
      const startDate = new Date(lowPriceCampaignConfig.start_date);
      const endDate = new Date(lowPriceCampaignConfig.end_date);
      if (now >= startDate && now <= endDate) {
        this.isDisplayLowPriceCampaignBanner = true;
      }
    },
    checkChristmasCampaign() {
      const now = new Date();
      const christmasCampaignConfig = CampaignConfig.christmas_campaign ?? [];
      const startDate = new Date(christmasCampaignConfig.start_date);
      const endDate = new Date(christmasCampaignConfig.end_date);
      if (now >= startDate && now <= endDate) {
        this.isOnChristmasCampaign = true;
      }
    },
    trackEvent(event) {
      dataLayer.push({ event });
    }
  },
  computed: {
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 580
        case 'sm':
          return 650
        case 'md':
          return 800
        case 'lg':
          return 800
        case 'xl':
          return 800
      }
    },
    getResaleCampaign () {// config のキャンペーン情報を配列で取得
      return VueConfig.resaleCampaign;
    },
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
}
</script>

<style scoped lang="scss">
.text-minimum {
  font-size: 0.5em;
}

.speech-bubble-title {
  z-index: 1;
  position: absolute;
  top: -20px;
}

.ai-balloon {
  &:focus:before {
    opacity: 0;
  }
  .v-card__text {
    position: relative;
    background-color: white;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.24);
    .balloon-arrow {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      margin: auto;
      border-style: solid;
    }
  }

}
</style>
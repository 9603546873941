<template>
  <div>
    <template v-for="(campaignItem, key) in campaignInfo">
      <div v-if="isCampaignActive(campaignItem)" id="campaign-banner-container" style="width: 100%;min-height: 64px;" :key="key">
        <div v-for="(campaignInfo, index) in campaignItem.campaign_info" class="campaign-banner-wrapper d-flex" style="width: 100%; height: fit-content"
             :style="{ backgroundColor: getBackgroundColor(campaignInfo) }">
          <campaign-banner
            :file="{ mobile: campaignInfo.mobile, pc: campaignInfo.pc }"
            :alt="campaignInfo.alt"
            :link="campaignInfo.link"
            :background-color="campaignInfo['background-color']"
            :target="campaignInfo.target"
            :key="index"
          ></campaign-banner>
        </div>
      </div>
    </template>
    <notification-bar></notification-bar>
    <slot></slot>
    <vb-book-selection-button></vb-book-selection-button>
  </div>
</template>

<script>
import CampaignBanner from "../atoms/campaign-banner.vue";
import NotificationBar from "../molecules/notification-bar.vue";
import VbBookSelectionButton from "../atoms/vb-book-selection-button.vue";


export default {
  name: "vb-single-column",
  components: {
    VbBookSelectionButton,
    NotificationBar,
    CampaignBanner,
  },
  props: {
    campaignInfo: {
      required: false
    }
  },
  data() {
    return {
      cdnUrl: VueConfig.cdn_url,
    }
  },
  methods: {
    isCampaignActive(campaignItem) {
      const now = new Date();
      const startDate = new Date(campaignItem.start_date);
      const endDate = new Date(campaignItem.end_date);
      return now >= startDate && now <= endDate;
    },
    getBackgroundColor(campaignInfo) {
      return campaignInfo["pc-tile-view"] && this.$route.path === campaignInfo["pc-tile-view"].display_page
        ? 'transparent'
        : campaignInfo['background-color'];
    }
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  picture {
    display: flex;
  }
  .notification-bar-inner {
    p {
      margin: 0;
      font-size: 14px;
    }
  }
  img.campaign-image {
    max-width: 100%;
  }
}
</style>
<template>
  <v-card flat color="transparent">

    <v-card-title class="pt-0 pl-0 pr-8 pb-1 text-h5 font-weight-bold">
      <v-icon v-if="!hideIcon" class="mr-2" color="#2C2C2C">mdi-book-open-variant</v-icon>
      {{ title }}
      <a v-if="linkUrl" :href="linkUrl" class="d-inline-block ml-auto text-body-1">{{ link }}</a>
    </v-card-title>

    <v-card-subtitle v-if="subtitle" class="ma-0 pa-0 text-body-2 font-weight-bold word-break-normal" style="color: #2c2c2c">{{ subtitle }}</v-card-subtitle>

    <v-sheet
      :max-width="$vuetify.breakpoint.smAndDown ? '95%' : '100%'"
      color="transparent"
    >
      <div ref="sliderA" class="pt-4 pl-1 slide-content">
        <template v-if="$vuetify.breakpoint.smAndUp">
          <v-img v-if="!isShowGoRightElement && isVisibleSlider" @click="moveRight('sliderA')" class="slide-to-right"
                 src="/assets/images/index/right-arrow.svg"></v-img>
          <v-img v-if="isShowGoRightElement && isVisibleSlider" @click="moveElementTop('sliderA')" class="slide-to-right"
                 src="/assets/images/index/double-right-arrow.svg"></v-img>
          <v-img v-if="isShowGoLeftElement" @click="moveLeft('sliderA')" class="slide-to-left"
                 src="/assets/images/index/left-arrow.svg"></v-img>
        </template>
        <template v-for="(item,n) in items">
          <div class="slide-item">
            <v-card :width="cardWidth" :height="cardHeight" :style="`background-color: ${cardBgColor}`" min-height="212" class="mr-4 mb-6 rounded-lg" :href="item.url">
              <v-img height="110" :src=item.image :alt="item.title ? item.title : ''" class="rounded-b-lg-0　mb-4"></v-img>
              <v-card-title v-if="item.title" class="py-0 px-4 mb-1"><span class="text-body-1 font-weight-bold text-truncate">{{ item.title }}</span></v-card-title>
              <v-card-text v-if="item.description" class="pt-0 pb-0 px-4 text-body-2" style="color: #2c2c2c; letter-spacing: 0 !important" :style="lineClampStyle">{{ item.description }}</v-card-text>
            </v-card>
          </div>
        </template>
      </div>
    </v-sheet>

  </v-card>
</template>

<script>
import VbItem from "../../molecules/vb-item.vue";

export default {
  name: "slide-item",
  components: {
    VbItem,
  },
  props: {
    title: {type: String, required: true, default: null},
    subtitle: {type: String, required: false, default: null},
    link: {type: String, required: false, default: "もっと見る"},
    linkUrl: {type: String, required: false, default: null},
    items: {type: Array, required: false, default: []},
    hideIcon: {type: Boolean, required: false, default: false},
    cardWidth: {type: String, required: false, default: '240'},
    cardHeight: {type: String, required: false, default: '265'},
    cardBgColor: {type: String, required: false, default: null},
    lineClamp: {type: Number, required: false, default: 5},
  },
  data() {
    return {
      scrollPosition: 0,
      scrollWidth: 1,
      clientWidth: 0,
      sliderElement: null,
    }
  },
  methods: {
    next(ref) {
      this.$refs[ref].scrollOffset = 0;
    },
    moveRight(ref) {
      const element = this.$refs[ref];
      const clientWidth = element.clientWidth;
      const nowScrollPotion = element.scrollLeft;

      element.scrollTo({left: nowScrollPotion + clientWidth * 0.9, behavior: "smooth"});
    },
    moveLeft(ref) {
      const element = this.$refs[ref];
      const clientWidth = element.clientWidth;
      const nowScrollPotion = element.scrollLeft;

      element.scrollTo({left: nowScrollPotion - clientWidth * 0.9, behavior: "smooth"});
    },
    moveElementTop(ref) {
      const element = this.$refs[ref];

      element.scrollTo({left: 0, behavior: "smooth"});
    },
    handleResize() {
      if (this.$refs.sliderA) {
        this.setScrollPosition();
      }
    },
    setScrollPosition() {
      if (this.items.length) {
        const element = this.sliderElement;
        this.scrollPosition = element.scrollLeft;
        this.scrollWidth = element.scrollWidth;
        this.clientWidth = element.clientWidth;
      }
    }
  },
  computed: {
    hasNext() {
      return (ref) => {
        if (this.$refs[ref]) return this.$refs[ref].hasNext;
      }
    },
    isShowGoLeftElement() {
      return this.scrollPosition !== 0;
    },
    isShowGoRightElement() {
      return this.scrollPosition + this.clientWidth >= this.scrollWidth
    },
    isVisibleSlider() {
      return this.clientWidth < this.scrollWidth;
    },
    lineClampStyle() {
      const lines = this.lineClamp;
      return {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: lines,
        whiteSpace: 'normal'
      };
    }
  },
  mounted() {
    this.sliderElement = this.$refs.sliderA;
    window.addEventListener('resize', this.handleResize);
    this.setScrollPosition();
    this.sliderElement.onscroll = () => {
      this.setScrollPosition();
    }
  },
  beforeDestroy(){
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>

<style scoped lang="scss">
.slide-content {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  align-items: flex-end;
  .slide-to-left {
    overflow-x: auto;
    z-index: 1;
    top: 46%;
    left: 23px;
    right: auto;
    position: absolute;
  }
  .slide-to-right {
    overflow-x: auto;
    z-index: 1;
    top: 46%;
    left: auto;
    right: 44px;
    position: absolute;
  }
}
.slide-item {
  position: relative;
  flex: 0 0 auto;
}
::v-deep {
  .v-slide-group {
    &__prev,
    &__next,
    .slide-back-to-top {
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 1;
    }
    &__prev {
      left: 80px;
    }
    &__prev,
    .slide-to-left {
      top: -5px;
    }
    &__next,
    .slide-back-to-top {
      right: 108px;
      top: -12px;
      margin: auto;
    }
    &__next,
    .slide-to-right {
      top: -3px;
    }
    &__prev--disabled,
    &__next--disabled {
      display: none;
    }
    &__content {
      align-items: end;
      padding-right: 108px;
    }
  }
}
</style>